import React, { Component } from 'react';
import styled from 'styled-components';
import { mediumBreakpointMinus, lightBorderColor } from '../../constants';
import PropTypes from 'prop-types';

const ContentBoxDiv = styled.main`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: ${props => (props.roomyLayout ? '0 0 30px 0' : '0 30px 30px 30px')};
  border-bottom: 1px solid ${lightBorderColor};
  h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 20px 0;
    a {
      font-family: 'Raleway', sans-serif;
    }
  }
  h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    margin: 20px 0;
    width: 100%;
  }
  ul {
    padding-right: 20px;
    margin-top: 26px;
  }
  p {
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  }
  a {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    padding: 10px 30px 30px 30px;
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

class ContentBox extends Component {
  render() {
    const { children, roomyLayout } = this.props;
    return <ContentBoxDiv roomyLayout={roomyLayout}>{children}</ContentBoxDiv>;
  }
}

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  roomyLayout: PropTypes.bool,
};

export default ContentBoxDiv;
